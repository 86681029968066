import React from "react";
import {ARTICLE_11} from "shared/Types/articles";
import {useArticlesData} from "shared/Hooks/Articles/useArticlesData";
import SEO from "components/seo";

import {Author, Hero, Image, Layout, List, Text} from "components/BlogLayout";
import {TitleXL} from "../../components/Typography";
import {Container} from "../../components/BlogLayout/Text/styles";

const Article_11 = () => {
  const data = useArticlesData(ARTICLE_11);
  return (
    <Layout>
      <SEO title={data.SEO_title} description={data.seo} image={data.image}/>
      <Hero data={data}/>
      <List data={data.list.first}/>
      <Text data={data.text.first}/>
      <Text data={data.text.second}/>
      <Text data={data.text.third}/>
      <Text data={data.text.fourth}/>
      <Image data={data.images.first}/>
      <Text data={data.text.fifth}/>
      <Text data={data.text.sixth}/>
      <Image data={data.images.second}/>
      <Text data={data.text.seventh}/>
      <Text data={data.text.eighth}/>
      <Image data={data.images.third}/>
      <Text data={data.text.ninth}/>
      <Text data={data.text.tenth}/>
      <Image data={data.images.fourth}/>
      <Text data={data.text.eleventh}/>
      <Text data={data.text.twelfth}/>
      <Text data={data.text.thirteenth}/>
      <Text data={data.text.fourteenth}/>
      <Image data={data.images.fifth}/>
      <Text data={data.text.fifteenth}/>
      <Text data={data.text.sixteenth}/>
      <Image data={data.images.sixth}/>
      <Text data={data.text.seventeenth}/>
      <Text data={data.text.eighteenth}/>
      <Image data={data.images.seventh}/>
      <Container>
      <TitleXL>
        {data.ending}
      </TitleXL>
      </Container>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_11;
